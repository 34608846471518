import React, { useEffect, useState, useRef } from "react"
import logo from "../../assets/logos/main-logo-blanc-&.png"
import logo2 from "../../assets/logos/pitchou-logo-white.png"
import { Link } from "react-router-dom"

const AppHeader = () => {

  const [isToggleOpen, setIsToggleOpen] = useState(false)
  const navbarRef = useRef(null);

  const closeNavbar = () => {
    setIsToggleOpen(!isToggleOpen)
  };
  const closeNavbar2 = () => {
    if (isToggleOpen === true) {
      setIsToggleOpen(!isToggleOpen)
    }
  };

  const handleOutsideClick = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      closeNavbar();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      {/*<!-- Header --> */}
      <header className="fixed top-0 left-0 border-b-1 z-20 w-full border-b border-primary-dark bg-secondary-dark shadow-lg shadow-secondary-light/10 after:absolute after:top-full after:left-0 after:z-10 after:block after:h-px after:w-full  ">
        <div className="relative mx-auto max-w-full px-6 lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[96rem]">
          <nav
            aria-label="main navigation"
            className="flex lg:flex-col h-[7rem] lg:h-[8.5rem] items-stretch justify-between font-medium text-white"
            role="navigation"
          >
            {/*      <!-- Brand logo --> */}
            <div className="hidden lg:flex items-center w-20 md:w-40 lg:m-auto">
              <Link
                onClick={closeNavbar}
                aria-label="pitchou restaurant logo négatif"
                aria-current="page"
                tabIndex={0}
                className="flex items-center w-full whitespace-nowrap py-2 text-lg focus:outline-none"
                to="/"
              >
                <img src={logo} className="w-full" alt="flamme blanche" />
              </Link>
            </div>

            <div className="lg:hidden flex items-center w-20 lg:m-auto">
              <Link
                aria-label="pitchou logo"
                aria-current="page"
                onClick={closeNavbar2}

                className="flex items-center w-full whitespace-nowrap py-2 text-lg focus:outline-none"
                to="/"
              >
                <img src={logo2} className="w-1/3 m-auto" alt="pitchou grill & bar logo" />
              </Link>
            </div>

            {/*      <!-- Mobile trigger --> */}
            <button
              onClick={closeNavbar}
              className={`relative order-10 block h-10 w-10 self-center lg:hidden
                ${isToggleOpen
                  ? "visible opacity-100 [&_span:nth-child(1)]:w-6 [&_span:nth-child(1)]:translate-y-0 [&_span:nth-child(1)]:rotate-45 [&_span:nth-child(3)]:w-0 [&_span:nth-child(2)]:-rotate-45 "
                  : ""
                }
              `}
              aria-expanded={isToggleOpen ? "true" : "false"}
              aria-label="Toggle navigation"
            >
              <div className="absolute top-1/2 left-1/2 w-6 -translate-x-1/2 -translate-y-1/2 transform">
                <span
                  aria-hidden="true"
                  className="absolute block h-0.5 w-9/12 -translate-y-2 transform rounded-full bg-white transition-all duration-300"
                ></span>
                <span
                  aria-hidden="true"
                  className="absolute block h-0.5 w-6 transform rounded-full bg-white transition duration-300"
                ></span>
                <span
                  aria-hidden="true"
                  className="absolute block h-0.5 w-1/2 origin-top-left translate-y-2 transform rounded-full bg-white transition-all duration-300"
                ></span>
              </div>
            </button>
            {/*      <!-- Navigation links --> */}
            <ul
              role="menubar"
              aria-label="Select page"
              className={`absolute  drop-shadow-md top-0 left-0 z-[-1] h-[28.5rem] w-full justify-center overflow-hidden  overflow-y-auto overscroll-contain bg-secondary-dark px-8 pt-28 font-medium transition-[opacity,visibility] duration-300 lg:visible lg:relative lg:top-0  lg:z-0 lg:flex lg:h-full lg:w-auto lg:items-stretch lg:overflow-visible lg:bg-white/0 lg:px-0 lg:py-0  lg:pt-0 lg:opacity-100 ${isToggleOpen
                ? "visible opacity-100 backdrop-blur-sm"
                : "invisible opacity-0"
                }`}
            >
              <li role="none" className="flex items-stretch">
                <Link
                  onClick={closeNavbar}
                  role="menuitem"
                  aria-haspopup="false"
                  className="flex items-center gap-2 py-4 transition-colors duration-300 hover:underline focus:text-primary-light focus:underline underline-offset-4 focus:outline-none focus-visible:outline-none lg:px-8"
                  to="/"
                >
                  Accueil
                </Link>
              </li>
              <li role="none" className="flex items-stretch">
                <a
                  href="https://www.covermanager.com/reserve/module_restaurant/restaurante-pitchou/french"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='flex items-center gap-2 py-4 transition-colors duration-300 hover:underline focus:text-primary-light focus:underline underline-offset-4  focus:outline-none focus-visible:outline-none lg:px-8'
                >
                  Réserver
                </a>
              </li>
              <li role="none" className="flex items-stretch">
                <Link
                  onClick={closeNavbar}
                  role="menuitem"
                  aria-haspopup="false"
                  className="flex items-center gap-2 py-4 transition-colors duration-300 hover:underline focus:text-primary-light focus:underline underline-offset-4  focus:outline-none focus-visible:outline-none lg:px-8"
                  to="/menus"
                >
                  La Carte
                </Link>
              </li>
              <li role="none" className="flex items-stretch">
                <Link
                  onClick={closeNavbar}
                  role="menuitem"
                  aria-current="page"
                  aria-haspopup="false"
                  className="flex items-center gap-2 py-4 transition-colors duration-300 hover:underline focus:text-primary-light focus:underline underline-offset-4  focus:outline-none focus-visible:outline-none lg:px-8"
                  to="/équipe"
                >
                  <h1>Notre équipe</h1>
                </Link>
              </li>
              <li role="none" className="flex items-stretch">
                <Link
                  onClick={closeNavbar}
                  role="menuitem"
                  aria-haspopup="false"
                  className="flex items-center gap-2 py-4 transition-colors duration-300 hover:underline focus:text-primary-light focus:underline underline-offset-4  focus:outline-none focus-visible:outline-none lg:px-8"
                  to="/privatisation"
                >
                  Privatiser
                </Link>
              </li>
              <li role="none" className="flex items-stretch">
                <Link
                  onClick={closeNavbar}
                  role="menuitem"
                  aria-haspopup="false"
                  className="flex items-center gap-2 py-4 transition-colors duration-300 hover:underline focus:text-primary-light focus:underline underline-offset-4  focus:outline-none focus-visible:outline-none lg:px-8"
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      {/*<!-- End Navbar with Topbar--> */}
    </>
  )
}

export default AppHeader;
